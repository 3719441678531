
<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="900">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            {{ validateAccess('lhdn_update') ?'Update':'View'}} Supplier Profile ID {{ modalInfo.id }}
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height" v-if="validateAccess('lhdn_update')"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[1].selected"
                        :items="form[1].operator"
                        :rules="form[1].rule"
                        item-text="name"
                        item-value="OperatorID"
                        label="Operator" 
                        @change="execFilter('operator')"
                        hide-details="auto"
                        outlined
                        disabled
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[4].selected"
                        :items="form[4].idtype"
                        :rules="form[4].rule"
                        item-text="name"
                        item-value="id"
                        label="ID Type *"
                        hide-details="auto"
                        outlined
                        disabled
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[9].idno"
                        :rules="form[9].rule"
                        label="ID No. *"
                        hide-details="auto"
                        required
                        @change="hasValidated = false"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[8].tinno"
                        :rules="form[8].rule"
                        label="Tax Identification No. (TIN) *"
                        @change="hasValidated = false"
                        hide-details="auto"
                        required
                    ></v-text-field>
                </v-col>
                <v-col v-if="hasValidated == false" ><v-btn tile type="button" @click="validateTin()" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Validate</v-btn></v-col>
                <v-snackbar v-model="swal.notification" top :color="swal.scolor">{{ swal.message }}</v-snackbar>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[3].companyname"
                        :rules="form[3].rule"
                        label="Company Name *"
                        hide-details="auto"
                        required
                        :disabled="hasValidated == false ? true : false"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[10].selected"
                        :items="form[10].msic"
                        :rules="form[10].rule"
                        item-text="fullName"
                        item-value="id"
                        label="MSIC Type *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[6].sstno"
                        :rules="form[6].rule"
                        label="SST Registration No."
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[7].email"
                        :rules="form[7].rule"
                        label="Email *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[5].phonenumber"
                        :rules="form[5].rule"
                        label="Phone Number *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[13].address1"
                        :rules="form[13].rule"
                        label="Address Line 1 *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[14].address2"
                        :rules="form[14].rule"
                        label="Address Line 2 "
                        :disabled="hasValidated == false ? true : false"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[15].address3"
                        :rules="form[15].rule"
                        label="Address Line 3 "
                        :disabled="hasValidated == false ? true : false"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                
                

                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[16].postal"
                        :rules="form[16].rule"
                        label="Postal Code *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[17].city"
                        :rules="form[17].rule"
                        label="City *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[11].selected"
                        :items="form[11].state"
                        :rules="form[11].rule"
                        item-text="name"
                        item-value="id"
                        label="State *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        outlined
                    ></v-autocomplete>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[12].selected"
                        :items="form[12].country"
                        :rules="form[12].rule"
                        item-text="name"
                        item-value="id"
                        label="Country *"
                        hide-details="auto"
                        :disabled="hasValidated == false ? true : false"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.UpdateID + ' - ' + modalInfo.UpdatedBy"
                        label="Action By"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.UpdatedFrom == 'ADM' ? 'ADMIN' : modalInfo.UpdatedFrom"
                        label="Action From"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.updatedAt"
                        label="Updated Date"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form[19].status"
                    :label="`${form[19].status ? 'Enabled' : 'Disabled'}`"
                    :disabled="hasValidated == false ? true : false"
                    hide-details
                    ></v-switch>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="fill-height" v-else
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[1].selected"
                        :items="form[1].operator"
                        :rules="form[1].rule"
                        item-text="name"
                        item-value="OperatorID"
                        label="Operator" 
                        @change="execFilter('operator')"
                        hide-details="auto"
                        outlined
                        disabled
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[4].selected"
                        :items="form[4].idtype"
                        :rules="form[4].rule"
                        item-text="name"
                        item-value="id"
                        label="ID Type *"
                        hide-details="auto"
                        outlined
                        disabled
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[9].idno"
                        :rules="form[9].rule"
                        label="ID No. *"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[8].tinno"
                        :rules="form[8].rule"
                        label="Tax Identification No. (TIN) *"
                        disabled
                        hide-details="auto"
                        required
                    ></v-text-field>
                </v-col>
                <v-col v-if="hasValidated == false" ><v-btn tile type="button" @click="validateTin()" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Validate</v-btn></v-col>
                <v-snackbar v-model="swal.notification" top :color="swal.scolor">{{ swal.message }}</v-snackbar>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[3].companyname"
                        :rules="form[3].rule"
                        label="Company Name *"
                        hide-details="auto"
                        required
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[10].selected"
                        :items="form[10].msic"
                        :rules="form[10].rule"
                        item-text="fullName"
                        item-value="id"
                        label="MSIC Type *"
                        hide-details="auto"
                        disabled
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[6].sstno"
                        :rules="form[6].rule"
                        label="SST Registration No."
                        hide-details="auto"
                        disabled
                    ></v-text-field>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[7].email"
                        :rules="form[7].rule"
                        label="Email *"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[5].phonenumber"
                        :rules="form[5].rule"
                        label="Phone Number *"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[13].address1"
                        :rules="form[13].rule"
                        label="Address Line 1 *"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[14].address2"
                        :rules="form[14].rule"
                        label="Address Line 2 "
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[15].address3"
                        :rules="form[15].rule"
                        label="Address Line 3 "
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                
                

                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[16].postal"
                        :rules="form[16].rule"
                        label="Postal Code *"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[17].city"
                        :rules="form[17].rule"
                        label="City *"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[11].selected"
                        :items="form[11].state"
                        :rules="form[11].rule"
                        item-text="name"
                        item-value="id"
                        label="State *"
                        hide-details="auto"
                        disabled
                        outlined
                    ></v-autocomplete>
                </v-col>
                
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[12].selected"
                        :items="form[12].country"
                        :rules="form[12].rule"
                        item-text="name"
                        item-value="id"
                        label="Country *"
                        hide-details="auto"
                        disabled
                        outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.UpdateID + ' - ' + modalInfo.UpdatedBy"
                        label="Action By"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.UpdatedFrom == 'ADM' ? 'ADMIN' : modalInfo.UpdatedFrom"
                        label="Action From"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.updatedAt"
                        label="Updated Date"
                        hide-details="auto"
                        disabled
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Status</span>
                    <v-switch
                    color="success"
                    class="col-1 pl-0"
                    v-model="form[19].status"
                    :label="`${form[19].status ? 'Enabled' : 'Disabled'}`"
                    disabled
                    hide-details
                    ></v-switch>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="validateAccess('lhdn_update')" tile type="submit" small color="primary" :disabled="hasValidated == false ? true : false"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import alertbox2 from './Alert';
import {rule} from '../../assets/js/checkPerm';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {system: [], itemsFull: [], rule: [], selected: '' }, // 0
                {operator: [], itemsFull: [], rule: [], selected: '' }, // 1
                {carpark: [], itemsFull: [], rule: [], selected: '' }, // 2
                {companyname: '', rule: [] }, // 3
                {idtype: [], rule: [], selected: '2' }, // 4
                {phonenumber: '', rule: [], }, // 5
                {sstno: '', rule: [] }, // 6
                {email: '', rule: [] }, // 7
                {tinno: '', rule: [] }, // 8
                {idno: '', rule: [] }, // 9
                {msic: [], rule: [], selected: '' }, // 10
                {state: [], rule: [], selected: '' }, // 11
                {country: [], rule: [], selected: '' }, // 12
                {address1: '', rule: [] }, // 13
                {address2: '', rule: [] }, // 14
                {address3: '', rule: [] }, // 15
                {postal: '', rule: [] }, // 16
                {city: '', rule: [] }, // 17
                {supplierid: '', rule: [] }, // 18
                {status: 0, rule: [] }, // 19
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            searchlocation: '',
            componentloader: false,
            hasValidated: false
        }
    },

    components: {alertbox, alertbox2},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'markers.position': {
        handler: function(value) {
            this.form[6].baylatitude = value.lat;
            this.form[7].baylongitude = value.lng;
        },
        },
    },

    methods: {
        async getAllOperator(){
            // console.log(this.allInfo)
            const response = await this.$store.dispatch("listOperator", {search: ''});
            const r = response.data.record.filter((elem) => !this.allInfo.find(({ OperatorID }) => elem.OperatorID === OperatorID && OperatorID != this.modalInfo.OperatorID));

            const operator = [];
            for(var i=0; i<r.length; i++){
                if (r[i].SystemID === 2){
                    r[i].name = `${r[i].OperatorID} - ${r[i].OperatorName}`;
                    operator.push({name: r[i].name, OperatorID: r[i].OperatorID});
                }
            }
            this.form[1].operator = r;
            this.form[1].itemsFull = r;
        },

        execFilter(typ){
            let self = this;

            if (typ == 'operator'){
                this.form[2].carpark = [];
                this.form[2].selected = '';

                if (self.form[1].selected){
                    let itm = this.form[2].itemsFull.filter(i => i.OperatorID == self.form[1].selected);

                    this.form[2].carpark.push({name: 'Select Car Park', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form[2].carpark.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else{
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }
        },

        async getAllIdentifierList(){
            let response = await this.$store.dispatch("lhdnIdentifierList");
            this.form[4].idtype = response.data.record;
        },
        
        async getAllMsicList(){
            let response = await this.$store.dispatch("lhdnMsicList");
            response.data.record.map((i)=> i.fullName = `${i.id} - ${i.code}: ${i.name}`);
            this.form[10].msic = response.data.record;
        },
        async getAllStateList(){
            let response = await this.$store.dispatch("lhdnStateList");
            this.form[11].state = response.data.record;
        },
        async getAllCountryList(){
            let response = await this.$store.dispatch("lhdnCountryList");
            this.form[12].country = response.data.record;
        },


        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.OperatorID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        onRuleChange() {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
            this.form[3].rule = [];
            this.form[4].rule = [];
            this.form[5].rule = [];
            this.form[6].rule = [];
            this.form[7].rule = [];
            this.form[8].rule = [];
            this.form[9].rule = [];
        },

        validateAccess(val){
            console.log(val)
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async beforeCreateForm(flag){
            this.form[2].rule = [
                v => !!v || 'This is required',
            ];

            this.form[3].rule = [
                v => !!v || 'This is required',
            ];

            this.form[4].rule = [
                v => !!v || 'This is required',
            ];

            this.form[5].rule = [
                v => !!v || 'This is required',
            ];

            this.form[6].rule = [
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[7].rule = [
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter an email address',
            ];

            this.form[8].rule = [
                v => !!v || 'This is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.componentloader = true;
            this.searchlocation = "";
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.componentloader = false;
                this.$emit('closed', false);
            }
        },
        handleDelete(value){
            console.log(value)
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        async handleValue(){
            this.onRuleChange();
            this.getAllOperator();
            this.getAllIdentifierList();
            this.getAllMsicList();
            this.getAllStateList();
            this.getAllCountryList();

            this.hasValidated = true;

            this.form[0].selected = '';
            this.form[1].selected = this.modalInfo.OperatorID;
            this.form[2].selected = '';
            this.form[3].companyname = this.modalInfo.name;
            this.form[4].selected = this.modalInfo.identifierTypeId;
            this.form[5].phonenumber = this.modalInfo.telephone;
            this.form[6].sstno = this.modalInfo.sst != 'NA' ? this.modalInfo.sst : '';
            this.form[7].email = this.modalInfo.email;
            this.form[8].tinno = this.modalInfo.tin;
            this.form[9].idno = this.modalInfo.identifierValue;
            this.form[10].selected = this.modalInfo.Constraints_MSIC.id;
            this.form[11].selected = this.modalInfo.Constraints_State.id;
            this.form[12].selected = this.modalInfo.Constraints_Country.id;
            this.form[13].address1 = this.modalInfo.address1;
            this.form[14].address2 = this.modalInfo.address2;
            this.form[15].address3 = this.modalInfo.address3;
            this.form[16].postal = this.modalInfo.postal;
            this.form[17].city = this.modalInfo.city;
            this.form[18].supplierid = this.modalInfo.id;
            this.form[19].status = this.modalInfo.Status;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }
                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
                this.hasValidated = false;

            }
        },
        
        async validateTin(){

            const response = await this.$store.dispatch('validateTin', {idType: this.form[4].selected, idValue: this.form[9].idno, tin: this.form[8].tinno});
            if(response.data.record == true){
                this.hasValidated = true;
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `Success: Tax Identification No. validated!`;
            } else {
                this.hasValidated = false;
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = `Parameter Error: Invalid Detail!`;
            }
        },
    }
}
</script>